import React, { useState } from "react";
import pageImage from "images/HomepageLogo.png";
import { DocPageLayout, PublishedObject, SEO } from "components";

const PublishedObjectPage = ({ location }) => {
  const title = "ECL Published Object";
  const description = "Emerald Cloud Lab Object Browser";

  const [identifier, setIdentifier] = useState("");

  const searchParams = new URLSearchParams(location.search);
  const queryParamsId = searchParams.get("id");

  if (queryParamsId !== null && queryParamsId !== identifier) {
    setIdentifier(queryParamsId);
  }

  return (
    <DocPageLayout headerText="Documentation Center">
      <SEO
        image={pageImage}
        title={title}
        uri={location.pathname}
        description={description}
      />
      <PublishedObject identifier={identifier} />
    </DocPageLayout>
  );
};

export default PublishedObjectPage;
